import { useEffect, useState } from "react";

type TEnabled = boolean;
type TInterval = number;

export interface IAutoRefresh {
  enabled: TEnabled;
  period: TInterval;
}

interface IUseAutoRefresh extends IAutoRefresh {
  setEnabled: (enabled: boolean) => void;
  setPeriod: (every: number) => void;
  lastRefresh: Date;
  refresh: () => void;
}

export const useAutoRefresh = (
  initEnabled: TEnabled,
  initPreiod: TInterval,
  onRefresh: () => void = () => {}
): IUseAutoRefresh => {
  const [enabled, setEnabled] = useState(initEnabled);
  const [period, setPeriod] = useState(initPreiod);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>();
  const [lastRefresh, setLastRefresh] = useState(new Date());

  const refresh = () => {
    setLastRefresh(new Date());
    console.log(`Refresh: ${lastRefresh}`);
    onRefresh();
  };

  const handle = () => {
    if (enabled) {
      refresh();
      const intervalId = setInterval(refresh, period);
      setIntervalId(intervalId);
    }
  };

  useEffect(() => {
    handle();
    return () => clearInterval(intervalId);
  }, [enabled]);

  useEffect(() => {
    clearInterval(intervalId);
    handle();
    return () => clearInterval(intervalId);
  }, [period]);

  return {
    enabled,
    period,
    setEnabled,
    setPeriod,
    lastRefresh,
    refresh,
  };
};
