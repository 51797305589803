import { useEffect, useState } from "react";
import axios from "axios";

export type THealthCheckStatus = "success" | "error" | "warning" | undefined;
export type THealthCheckUrl = string;
export type THealthCheckContentType = "application/json" | "text/html";

export interface IUseHealthCheck {
  status: THealthCheckStatus;
  url: THealthCheckUrl;
  refresh: () => void;
}

export const useHealthCheck = (
  url: THealthCheckUrl,
  contentType: THealthCheckContentType
): IUseHealthCheck => {
  const [status, setStatus] = useState<THealthCheckStatus>();

  const checkStatus = () => {
    console.log(`Checking status: ${url}`);
    axios
      .get(url)
      .then((res) => {
        if (res.headers["content-type"].includes(contentType)) {
          setStatus("success");
        } else {
          setStatus("error");
        }
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          setStatus("warning");
        } else {
          setStatus("error");
        }
      });
  };

  useEffect(() => {
    checkStatus();
  }, []);

  return {
    //
    status,
    url,
    refresh: checkStatus,
  };
};
