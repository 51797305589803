import React, { useEffect, useState } from "react";
import {
  ConfigProvider,
  Segmented,
  Space,
  Spin,
  theme,
  Typography,
} from "antd";
import { HealthChecksPage } from "./pages/HealthChecksPage";

type TTheme = "light" | "dark";

export const App = () => {
  const { defaultAlgorithm, darkAlgorithm } = theme;
  const [currentTheme, setCurrentTheme] = useState<TTheme | undefined>(
    undefined
  );

  useEffect(() => {
    const previousTheme = localStorage.getItem("theme");
    previousTheme !== null
      ? setCurrentTheme(previousTheme as TTheme)
      : setCurrentTheme("dark");
  }, []);

  useEffect(() => {
    if (currentTheme === undefined) return;
    localStorage.setItem("theme", currentTheme);
    document.body.style.backgroundColor =
      currentTheme === "dark" ? "black" : "white";
  }, [currentTheme]);

  if (currentTheme === undefined) {
    return <Spin />;
  }

  return (
    <>
      <ConfigProvider
        theme={{
          algorithm: currentTheme === "dark" ? darkAlgorithm : defaultAlgorithm,
        }}
      >
        <Space direction="vertical" style={{ margin: "1%" }}>
          <Space direction={"vertical"}>
            <Typography.Title>Theme</Typography.Title>
            <Segmented
              value={currentTheme}
              onChange={(value) => setCurrentTheme(value as TTheme)}
              options={[
                { value: "light", label: "Light" },
                { value: "dark", label: "Dark" },
              ]}
            />
          </Space>
          <HealthChecksPage />
        </Space>
      </ConfigProvider>
    </>
  );
};
