import React from "react";
import { Alert, Button, Space, Spin, Typography } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { IUseHealthCheck } from "../../hooks/useHealthCheck";

export interface IHealthCheckProps {
  label: string;
  url?: string;
  healthCheck: IUseHealthCheck;
}

export const HealthCheck: React.FC<IHealthCheckProps> = ({
  label,
  url,
  healthCheck: { url: healthCheckUrl, status },
}) => {
  return (
    <>
      <Alert
        message={
          <Space>
            <Typography.Text style={{}}>{label}</Typography.Text>
            {status === "warning" && (
              <Typography.Text style={{ fontSize: "smaller" }}>
                CORS or a internet issue.
              </Typography.Text>
            )}
            <Button
              type={"text"}
              onClick={() => {
                window.open(url ?? healthCheckUrl, "_blank");
              }}
              icon={<LinkOutlined />}
            />
          </Space>
        }
        type={status}
        showIcon
        icon={status === undefined ? <Spin size={"small"} /> : undefined}
      />
    </>
  );
};
